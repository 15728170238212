.privacy-policy {
  padding: 20px;
  margin: auto;
  max-width: 1000px;
}

.privacy-policy > p {
  font-size: 14px;
  margin: 0 0 10px 0;
}

.privacy-policy > h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0px 0 10px 0;
}

.privacy-policy > p > ul {
  margin: 0 0 0 2em;
}
