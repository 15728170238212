.screen.splash {
  background: var(--primary-light);
  text-align: center;
  overflow: hidden;
}
.splash-logo {
  width: 50px;
  margin-top: 18px;
  margin-bottom: 10vh;
}

@keyframes fade-in {
  to { opacity: 1; }
}

@keyframes fade-out {
  to { opacity: 0; }
}

@keyframes move-character-in {
  to { transform: translateY(0); }
}

@keyframes move-character-out {
  to { transform: translateY(-80vh); }
}

#splash-welcome {
  will-change: opacity;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#splash-name {
  will-change: opacity;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 2s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
}

#splash-character {
  height: 80vw;
  max-height: 350px;
  position: relative;
  bottom: 15px;

  transform: translateY(75vh);
  animation-name: move-character-in;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1, 0.9, 1.05);
}

#splash-welcome.exit {
  will-change: opacity;
  opacity: 1;
  animation-name: fade-out;
  animation-delay: 0.75s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
#splash-name.exit {
  will-change: opacity;
  opacity: 1;
  animation-name: fade-out;
  animation-delay: 0.25s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

#splash-character.exit {
  transform: translateY(0vh);
  animation-name: move-character-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.1, -0.05, 1, 0);
}
