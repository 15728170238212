.app-layout {
  display: grid;
  grid-template-rows: var(--app-header-height) var(--app-title-height) 1fr var(--app-footer-height);
  width: 100vw;
  height: 100vh;
}

.app-header {
  background: var(--background-light);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 18px;
}
.app-header-logo {
  cursor: pointer;
  position: relative;
}
.app-footer {
  background: var(--primary);
}

.menu-header-top {
  background: var(--background-light);
  border-bottom: 5px solid var(--primary-light);
  height: 160px;
  position: relative;
}
.menu-header-img {
  position: absolute;
  width: 120px;
  height: 120px;
  bottom: 0px;
  right: 90px;
}
.menu-header-logo {
  position: absolute;
  bottom: -10px;
  right: 70px;
}
.menu-header-txt {
  background: var(--background-light);
  font-size: 5vw !important;
  text-align: center;
  padding: 1em 2em;
}
.menu-item {
  padding: 0.12em 2.5em;
}
.menu-item.spacer {
  margin-top: 1.5em;
}

.menu-footer {
  background: var(--background-light);
  color: var(--text) !important;
  cursor: pointer;
  height: var(--app-footer-height);
  width: 70vw;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.title-header {
  background: var(--primary);
  position: relative;
  transition: background var(--default-transition);
  font-size: 20px !important;
}
.title-header.hero-lg {
  background: var(--background-dark);
}

.title-header-img {
  position: absolute;
  right: 5px;
  bottom: -5px;
  height: 0px;
  transition: height var(--default-transition);
  z-index: 1;
}
.title-header-img.hero-md {
  height: 100px;
}
.title-header-img.hero-lg {
  height: 140px;
}
.title-header-txt {
  position: relative;
  z-index: 1;
}

.screen {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.screen.login {
  background: var(--primary-light);
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-rows: 1fr 15em 40px var(--app-footer-height);
}

.login-logo {
  width: 60vw;
  max-width: 210px;
  margin: 0 auto;
  z-index: 0;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
}

#login-form {
  margin: auto;
  z-index: 1;
}
.loginfield-box {
  display: flex;
  flex-flow: row wrap;
  padding: 8px 5px;
}
.loginfield-input {
  background: var(--background-light);
  color: var(--text-dark);
  border: none;
  font-size: 1em;
  outline: none;
  padding: .7em;
}
.loginfield-input[type='checkbox'] {
  zoom: 1.5;
  margin-left: .15em;
}
@media screen and (max-width: 767px) {
  _::-webkit-full-page-media, _:future, :root .loginfield-input[type='checkbox'] {
    zoom: 1;
  }
}
.login-pass-text {
  font-size: 13px;
  padding: 8px;
}

.outlined-text {
  text-anchor: middle;
  fill: var(--text-light);
}
.outlined {
  text-anchor: middle;
  stroke:black;
  stroke-width: 5.5px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
