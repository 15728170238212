.adminfield {
  padding-bottom: 5px;
}
.adminfield > .title {
  font-size: 12px;
  margin: 0px 3px 5px 3px;
}
.adminfield > .content {
  font-size: 12px;
  font-weight: 700;
}

.avatar-editor {
  background: var(--secondary);
  position: fixed;
  bottom: 75px;
  left: 0px;
  right: 0px;
  top: 0px;
  overflow-y: auto;
  padding: 30px;
  z-index: 15;
}
.upload-button {
  display: inline-block;
  font-weight: bold;
  margin-left: 3px;
  padding: .5em 1em;
}
.upload-button.disabled {
  color: var(--secondary);
}
.avatar-filename {
  font-weight: 300;
  margin-left: 2em;
}

.icon-sm {
  font-size: .7em;
  padding: 0 .45em;
}

.slot-item {
  background: var(--background-light);
  position: relative;
}
.slot-item:not(:first-child) {
  margin-top: 20px;
}
.slot-item-indicator {
  color: var(--background-light);
  padding: 3px 7px;
  font-size: 11px;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
  transition: background var(--default-transition);
}
.slot-item-indicator.full { background: var(--error); }
.slot-info { padding: 5px; }
.slot-content {
  margin: 15px;
  text-align: center;
}
.slot-button {
  font-size: 14px;
  padding: 8px;
  margin: 0 !important;
  width: 100%;
  text-align: center;
}
.slot-button:hover { cursor: pointer; }
.slot-button.slot {
  background: var(--text);
}
.slot-button.claim {
  background: var(--error);
}
.slot-group-icon {
  width: 20px;
  height: 20px;
}
